// src/components/CryptoChart.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

function CryptoChart({ symbol, togglePin, isPinned, priceChange, volume }) {
  const [chartData, setChartData] = useState([]);
  const [isFutures, setIsFutures] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState('5m'); // Выбранный таймфрейм

  // Загрузка данных о графике
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${selectedTimeframe}&limit=130`);
        const formattedData = response.data.map(entry => {
          return {
            x: new Date(entry[0]),
            y: [entry[1], entry[2], entry[3], entry[4]],
            volume: volume
          };
        });
        setChartData(formattedData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [symbol, selectedTimeframe, volume]);

  // Проверка, является ли символ фьючерсом
  useEffect(() => {
    const checkFutures = async () => {
      try {
        const futuresResponse = await axios.get(`https://fapi.binance.com/fapi/v1/ticker/24hr?symbol=${symbol}`);
        if (futuresResponse.data && futuresResponse.data.symbol) {
          setIsFutures(true);
        } else {
          setIsFutures(false);
        }
      } catch (error) {
        console.error('Error checking futures:', error);
        setIsFutures(false); // Устанавливаем false в случае ошибки
      }
    };
    checkFutures();
  }, [symbol]);

  // Обработчик изменения выбранного таймфрейма
  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
  };

  const options = {
    chart: {
      type: 'candlestick',
      height: 500,
      foreColor: '#000000',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      tooltip: {
        enabled: false
      }
    }
  };

  return (
    <div style={{ width: '520px', height: '400px', margin: '10px', position: 'relative' }}>
      {isPinned ? (
        <button onClick={() => togglePin(symbol)} style={{ position: 'absolute', right: 0, top: 0, zIndex: 2 }}>
          Unpin
        </button>
      ) : (
        <button onClick={() => togglePin(symbol)} style={{ position: 'absolute', right: 0, top: 0, zIndex: 2 }}>
          Pin
        </button>
      )}
      <div style={{ display: 'flex' }}>
        <h4>{symbol}</h4>
        {isFutures && (
          <span style={{ color: 'blue', marginLeft: '5px', fontWeight: 'bold' }}>
            F
          </span>
        )}
      
      <div id="timeframe">
        <button onClick={() => handleTimeframeChange('1m')} className={selectedTimeframe === '1m' ? 'active' : ''}>1M</button>
        <button onClick={() => handleTimeframeChange('5m')} className={selectedTimeframe === '5m' ? 'active' : ''}>5M</button>
        <button onClick={() => handleTimeframeChange('15m')} className={selectedTimeframe === '15m' ? 'active' : ''}>15M</button>
        <button onClick={() => handleTimeframeChange('1h')} className={selectedTimeframe === '1h' ? 'active' : ''}>1H</button>
        <button onClick={() => handleTimeframeChange('4h')} className={selectedTimeframe === '4h' ? 'active' : ''}>4H</button>
        <button onClick={() => handleTimeframeChange('1d')} className={selectedTimeframe === '1d' ? 'active' : ''}>1D</button>
      </div>

      {typeof priceChange === 'number' && (
          <span style={{ color: priceChange > 0 ? 'green' : 'red', marginLeft: 'auto', textAlign: 'right', marginTop: '35px', fontWeight: 'bold', fontSize: '20px' }}>
            {priceChange.toFixed(2)}%
          </span>
        )}
    </div>
      <ReactApexChart options={options} series={[{ data: chartData }]} type="candlestick" />
    </div>
  );
}

export default CryptoChart;
