// src/components/CryptoCharts.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CryptoChart from './CryptoChart';

function CryptoCharts() {
  const [data, setData] = useState([]);
  const [sortType, setSortType] = useState('priceChange'); // Новое состояние для типа сортировки
  const [sortAscending, setSortAscending] = useState(JSON.parse(localStorage.getItem('sortAscending')) || false);
  const savedPinnedCharts = JSON.parse(localStorage.getItem('pinnedCharts')) || [];
  const [pinnedCharts, setPinnedCharts] = useState(savedPinnedCharts);

  useEffect(() => {
    localStorage.setItem('pinnedCharts', JSON.stringify(pinnedCharts));
  }, [pinnedCharts]);

  useEffect(() => {
    localStorage.setItem('sortAscending', JSON.stringify(sortAscending));
  }, [sortAscending]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('https://api.binance.com/api/v3/ticker/24hr');
      let growthData = response.data
        .filter(ticker => ticker.symbol.endsWith('USDT'))
        .map(ticker => ({
          symbol: ticker.symbol, 
          priceChange: parseFloat(ticker.priceChangePercent),
          volume: parseFloat(ticker.volume),
          lastPrice: parseFloat(ticker.lastPrice),
          tradeVolumePrice: parseFloat(ticker.volume) * parseFloat(ticker.lastPrice) // Расчет объема торгов, умноженного на цену
        }))
        .filter(item => !/(UPUSDT|DOWNUSDT)/i.test(item.symbol))
        .filter(item => !pinnedCharts.some(pinned => pinned.symbol === item.symbol));

      // Сортировка на основе выбранного типа
      growthData = growthData.sort((a, b) => {
        const sortValA = sortType === 'tradeVolumePrice' ? a.tradeVolumePrice : a.priceChange;
        const sortValB = sortType === 'tradeVolumePrice' ? b.tradeVolumePrice : b.priceChange;
        return sortAscending ? sortValA - sortValB : sortValB - sortValA;
      });

      setData(growthData.slice(0, 20));
    };
    
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => clearInterval(intervalId);
  }, [pinnedCharts, sortAscending, sortType]);

  const togglePin = (chart) => {
    if (pinnedCharts.some(pinned => pinned.symbol === chart.symbol)) {
      setPinnedCharts(prev => prev.filter(pinned => pinned.symbol !== chart.symbol));
    } else {
      setPinnedCharts(prev => [...prev, chart]);
    }
  };

  useEffect(() => {
    const pageReloadIntervalId = setInterval(() => {
      window.location.reload();
    }, 300000);

    return () => clearInterval(pageReloadIntervalId);
  }, []);

  const toggleSort = () => {
    setSortAscending(!sortAscending);
  };

  const changeSortType = (type) => {
    setSortType(type);
  };

  return (
      <div>
        <button onClick={() => changeSortType('priceChange')} style={{ margin: '3px' }}>
          Sort by Price Change
        </button>
        <button onClick={() => changeSortType('tradeVolumePrice')} style={{ margin: '3px' }}>
          Sort by Trade Volume * Price
        </button>
        <button onClick={toggleSort} style={{ margin: '3px' }}>
          {sortAscending ? 'Sort Descending' : 'Sort Ascending'}
        </button>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {pinnedCharts.map(pinned => (
            <div key={pinned.symbol} style={{ border: '2px solid slategrey', borderRadius: '10px', margin: '10px' }}>
              <CryptoChart symbol={pinned.symbol} togglePin={() => togglePin(pinned)} isPinned priceChange={pinned.priceChange} volume={pinned.volume} />
            </div>
          ))}
          {data.map((item, index) => (
            <CryptoChart key={index} symbol={item.symbol} togglePin={() => togglePin(item)} isPinned={pinnedCharts.some(pinned => pinned.symbol === item.symbol)} priceChange={item.priceChange} volume={item.volume} />
          ))}
        </div>
      </div>
  );
}

export default CryptoCharts;
